function preserveAnchorNodePosition(document, callback) {
  return preservePosition(findAnchorNode(document), callback);
}
function preservePosition(anchorNode, callback) {
  var node = anchorNode;

  if (!node) {
    return Promise.resolve(callback());
  }

  var documentElement = node.ownerDocument.documentElement;

  function computeAncestorBoundingRects(element) {
    var rects = [];

    while (element) {
      var _element$getBoundingC = element.getBoundingClientRect(),
          top = _element$getBoundingC.top,
          left = _element$getBoundingC.left;

      rects.push({
        element: element,
        top: top,
        left: left
      });
      element = element.parentElement;
    }

    return rects;
  }

  function firstAttachedBoundingRect(rects) {
    for (var i = 0; i < rects.length; i++) {
      var rect = rects[i];

      if (documentElement.contains(rect.element)) {
        return rect;
      }
    }
  }

  var origBoundingRects = computeAncestorBoundingRects(node);
  return Promise.resolve(callback()).then(function (result) {
    var origBoundingRect = firstAttachedBoundingRect(origBoundingRects);

    if (origBoundingRect) {
      node = origBoundingRect.element;
      var origTop = origBoundingRect.top;
      var origLeft = origBoundingRect.left;

      var _node$getBoundingClie = node.getBoundingClientRect(),
          top = _node$getBoundingClie.top,
          left = _node$getBoundingClie.left;

      cumulativeScrollBy(node, left - origLeft, top - origTop);
    }

    return result;
  });
}
function findAnchorNode(document) {
  if (document.activeElement !== document.body) {
    return document.activeElement;
  }

  var hoverElements = document.querySelectorAll(':hover');
  var len = hoverElements.length;

  if (len) {
    return hoverElements[len - 1];
  }
}

function cumulativeScrollBy(element, x, y) {
  var document = element.ownerDocument;
  var window = document.defaultView;

  function scrollOffsets(element) {
    if (element.offsetParent) {
      return {
        top: element.scrollTop,
        left: element.scrollLeft
      };
    } else {
      return {
        top: window.pageYOffset,
        left: window.pageXOffset
      };
    }
  }

  function scrollBy(element, x, y) {
    if (x === 0 && y === 0) {
      return [0, 0];
    }

    var origScroll = scrollOffsets(element);
    var top = origScroll.top + y;
    var left = origScroll.left + x;

    if (element === document || element === window || element === document.documentElement || element === document.body) {
      document.defaultView.scrollTo(left, top);
    } else {
      element.scrollTop = top;
      element.scrollLeft = left;
    }

    var newScroll = scrollOffsets(element);
    return [newScroll.left - origScroll.left, newScroll.top - origScroll.top];
  }

  function overflowParent(el) {
    var element = el;

    if (!element.offsetParent || element === document.body) {
      return;
    }

    while (element !== document.body) {
      if (element.parentElement) {
        element = element.parentElement;
      } else {
        return;
      }

      var _window$getComputedSt = window.getComputedStyle(element),
          position = _window$getComputedSt.position,
          overflowY = _window$getComputedSt.overflowY,
          overflowX = _window$getComputedSt.overflowX;

      if (position === 'fixed' || overflowY === 'auto' || overflowX === 'auto' || overflowY === 'scroll' || overflowX === 'scroll') {
        break;
      }
    }

    return element;
  }

  var container = overflowParent(element);
  var cumulativeX = 0;
  var cumulativeY = 0;

  while (container) {
    var scrolled = scrollBy(container, x - cumulativeX, y - cumulativeY);
    cumulativeX += scrolled[0];
    cumulativeY += scrolled[1];

    if (cumulativeX === x && cumulativeY === y) {
      break;
    }

    container = overflowParent(container);
  }
}

export { preserveAnchorNodePosition, preservePosition, findAnchorNode };
